$('.info-card-title').click(function () {
	if ($(this).attr('aria-expanded') == 'true') {
		$(this).children('i').removeClass("fa-angle-up");
		$(this).children('i').addClass("fa-angle-down");
	} else if ($(this).attr('aria-expanded') == 'false') {
		$(this).children('i').removeClass("fa-angle-down");
		$(this).children('i').addClass("fa-angle-up");
	}
});
$(document).ready(function ($) {
	//===============================================
	//TRACKING
    //Versao 3
	/*var queryString = new URL(window.location).search;
	document.querySelectorAll("[href]").forEach(link => {
		try
		{ 
			//Apanha Todos comecem por 
			// "/" -> Imagens  
			// "vitorinos" -> Vitorinos  
			if (link.href.startsWith("/") || link.href.startsWith(".") || link.href.indexOf("vitorinos") !== -1 )
			{			
				//Excluir ancoras
				if(link.href.startsWith("#")==false && link.href.includes("#") == false)
				{
					var current = link.href;
					link.href = current + queryString;
				}
			}
			
			//Teve de ser feito por causa das MODALS
			if(link.href.includes("#"))
			{
				var current = link.href;
				link.href = current.substring(current.indexOf("#"));
			}
		}
		catch(e){}
	});
	
	var local = window.location.href;
	var result = local.replace(/\//g, "«").replace(/\?/g, "»");
	//console.log(result);

	var sid = $('meta[name=csrf-token]').attr('content');
	//console.log(sid);

	var track = new Image();
	track.src = "https://api.vitorinos.pt/api/analytics/ins/" + result + "/Entrou%20na%20Pagina/" + sid;
*/
	//===============================================

});

